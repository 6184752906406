import { authConstants } from "../_constants";
import { authServices } from "../_services";

export const authActions = {
  signUp,
  verifyOTP,
  requestOTP,
  passwordSignIn,
  logout
};

function signUp(name, phoneNumber, password) {
  return dispatch => {
    dispatch(request(name, phoneNumber, password));
    authServices.signUp(name, phoneNumber, password).then(
      response => {
        dispatch(success(response.data));
      },
      error => {
        console.log(error);
        alert(error.toString());
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(name, phoneNumber, password) {
    return { type: authConstants.SIGN_UP_REQUEST, name, phoneNumber, password };
  }
  function success(data) {
    return { type: authConstants.SIGN_UP_SUCCESS, data };
  }
  function failure(error) {
    return { type: authConstants.SIGN_UP_FAILURE, error };
  }
}

function verifyOTP(phoneNumber, OTP) {
  return dispatch => {
    dispatch(request());
    authServices.verifyOTP(phoneNumber, OTP).then(
      response => {
        dispatch(success(response.data));
      },
      error => {
        console.log(error);
        alert(error.toString());
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: authConstants.VERIFY_OTP_REQUEST };
  }
  function success(data) {
    return { type: authConstants.VERIFY_OTP_SUCCESS, data };
  }
  function failure(error) {
    return { type: authConstants.VERIFY_OTP_FAILURE, error };
  }
}

function requestOTP(phoneNumber) {
  return dispatch => {
    dispatch(request());
    authServices.requestOTP(phoneNumber).then(
      response => {
        dispatch(success(response.data));
      },
      error => {
        console.log(error);
        alert(error.toString());
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: authConstants.REQUEST_OTP_REQUEST };
  }
  function success(data) {
    return { type: authConstants.REQUEST_OTP_SUCCESS, data };
  }
  function failure(error) {
    return { type: authConstants.REQUEST_OTP_FAILURE, error };
  }
}

function passwordSignIn(phoneNumber, password) {
  return dispatch => {
    dispatch(request());
    authServices.passwordSignIn(phoneNumber, password).then(
      response => {
        dispatch(success(response.data));
      },
      error => {
        alert(error.toString());
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: authConstants.PASSWORD_SIGNIN_REQUEST };
  }
  function success(data) {
    return { type: authConstants.PASSWORD_SIGNIN_SUCCESS, data };
  }
  function failure(error) {
    return { type: authConstants.PASSWORD_SIGNIN_FAILURE, error };
  }
}

function logout() {
  return dispatch => {
    dispatch(success());
  };
  function success() {
    return { type: authConstants.LOGOUT };
  }
}

/* function requestOTP(type, phoneNumber) {
    return dispatch => {
        dispatch(request());
        authServices.requestOTP(type, phoneNumber)
            .then(
                response => {
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: deviceConstants.REQUEST_OTP_REQUEST } }
    function success() { return { type: deviceConstants.REQUEST_OTP_SUCCESS } }
    function failure(error) { return { type: deviceConstants.REQUEST_OTP_FAILURE, error } }
}

function signUp(name, phoneNumber, password) {
    return dispatch => { 
        dispatch(request(name, phoneNumber, password));
        authServices.verifyOTP(otp)
            .then(
                response => {
                    dispatch(success());
                    dispatch(request1());
                    authServices.signUp(name, phoneNumber, password)
                    .then(
                        response1 => {
                            dispatch(success1(response1.data));
                        },
                        error1 => {
                            dispatch(failure1(error1));
                        }
                    )
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );

    };

    function request(name, phoneNumber, password) { return { type: authConstants.SIGN_UP_REQUEST, name, phoneNumber, password } }
    function success() { return { type: authConstants.SIGN_UP_SUCCESS } }
    function failure(error) { return { type: authConstants.SIGN_UP_FAILURE, error } }
    function request1() { return { type: authConstants.SIGN_UP_REQUEST1 } }
    function success1(userData) { return { type: authConstants.SIGN_UP_SUCCESS1, userData } }
    function failure1(error1) { return { type: authConstants.SIGN_UP_FAILURE1, error1 } }
}

function passwordSignIn(phoneNumber, password) {
    return dispatch => { 
        dispatch(request());
        authServices.passwordSignIn(phoneNumber, password)
            .then(
                response => {
                    dispatch(success(response.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: deviceConstants.PASSWORD_SIGNIN_REQUEST } }
    function success() { return { type: deviceConstants.PASSWORD_SIGNIN_SUCCESS } }
    function failure(error) { return { type: deviceConstants.PASSWORD_SIGNIN_FAILURE, error } }
}

function otpSignIn(phoneNumber, otp) {
    return dispatch => { 
        dispatch(request(name, phoneNumber, password, otp));
        authServices.verifyOTP(otp)
            .then(
                response => {
                    dispatch(success()); 
                    dispatch(request1());
                    authServices.signUp(name, phoneNumber, password)
                    .then(
                        response1 => {
                            dispatch(success1(response1.data));
                        },
                        error1 => {
                            dispatch(failure1(error1));
                        }
                    )
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            ); 
    };

    function request(name, phoneNumber, password, otp) { return { type: authConstants.SIGN_UP_REQUEST, name, phoneNumber, password, otp } }
    function success() { return { type: authConstants.SIGN_UP_SUCCESS } }
    function failure(error) { return { type: authConstants.SIGN_UP_FAILURE, error } }
    function request1() { return { type: authConstants.SIGN_UP_REQUEST1 } }
    function success1(userData) { return { type: authConstants.SIGN_UP_SUCCESS1, userData } }
    function failure1(error1) { return { type: authConstants.SIGN_UP_FAILURE1, error1 } }
}

 */
