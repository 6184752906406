export const requirementsServices = {
  postRequirements,
  payment,
};

const hostname = "findmyroom.in";

const stanzaPlaces = [
  "Koramangala",
  "HSR Layout",
  "HSR layout Sector 2, 1st Stage, BTM Layout",
  "HSR Layout 5th Sector",
  "Electronic City",
  "Electronic City Phase II, Electronic City",
  "Electronics City Phase 1, Electronic City",
  "Aundh",
  "Baner",
  "Wakad",
  "Kharghar Golf Course, Kharghar",
  "Hinjewadi",
  "Powai",
  "Chembur",
  "Bandra",
  "Gachibowli",
  "Madhapur",
  "Kondapur",
  "Sohna",
  "HUDA Complex",
  "DLF Golf Course, Sector 42",
  "Mahabalipuram",
  "Nungambakkam",

  //22nd June 2022
  "Fursungi",
  "Bellandur",
  "Hinjawadi",
  "Karapakkam",
  "Kukatpally",
  "Manayata Tech Park, Nagavara",
  "Varthur",
  "Whitefield",
  "Perungudi",
  "Nagavara",
  "Navalur",
  "Marathahalli",
  "Kundalahalli, Brookefield",
  "Sholinganallur",
  "BTM Layout",
  "BTM 1st Stage",
  "BTM 2nd Stage",
  "Kothrud",

  "Electronics City Phase II, Electronic City",
  "Phase 2, Electronic City",
  "Electronics City Phase 1, Electronic City",
  "Electronics City",
];

function postRequirements(requirementsDetails) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "omit",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(requirementsDetails),
  };
  if (
    requirementsDetails.requirementType !== ["entire-house"] &&
    requirementsDetails.rent >= 7500 &&
    stanzaPlaces.includes(requirementsDetails.locality)
  ) {
    const stanzaRequestOptions = {
      method: "POST",
      mode: "cors",
      //cache: "no-cache",
      //credentials: "omit",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        headerAuth: "c2510261f27872a22a160f463dc2ac2111ae2",
        "Content-Type": "application/json",
        Cookie: "token=8bd0e9ca-70d3-42de-a281-f93887db1d30",
      },
    };

    // let micromarketName;
    // if (
    //   requirementsDetails.locality === "Phase 2, Electronic City" ||
    //   requirementsDetails.locality === "Phase 2, Electronics City"
    // ) {
    //   micromarketName = "Electronic City Phase 2";
    // } else if (
    //   requirementsDetails.locality.includes("Electronic City") ||
    //   requirementsDetails.locality.includes("Electronics City")
    // ) {
    //   micromarketName = "Electronic City Phase 1";
    // } else {
    //   micromarketName = requirementsDetails.locality;
    // }
    // const body = {
    //   cityName: requirementsDetails.city,
    //   micromarketName,
    //   firstName: requirementsDetails.name,
    //   lastName: requirementsDetails.name,
    //   leadCreationInterface: "EXTERNAL",
    //   leadSourceDesc:
    //     requirementsDetails.locality.includes("Electronic City") ||
    //     requirementsDetails.locality.includes("Electronics City")
    //       ? "Lead LP-form-1"
    //       : "Find my room",
    //   phone: requirementsDetails.phoneNumber,
    //   accommodationType: "PG",
    //   email: "sanchit.dua@test.com",
    //   profession: "Working Professional",
    // };

    // if (
    //   requirementsDetails.locality.includes("Electronic City") ||
    //   requirementsDetails.locality.includes("Electronics City")
    // ) {
    //   body.utmSource = "google";
    //   body.utmMedium = "cpl";
    // }

    // stanzaRequestOptions.body = JSON.stringify(body);

    // fetch(
    //   "https://prodims.stanzaliving.com/leadservice/lead/external/create",
    //   stanzaRequestOptions
    // );
  }

  let accommodationType = 'Other'

  if(requirementsDetails.requirementType.includes('entire-house')) {
    accommodationType = 'House'
  }
  else {
    accommodationType = "Room"
  }

  // post to hello world
  const hqOptions = {
    method: "POST",
    //cache: "no-cache",
    //credentials: "omit",
    headers: {
      authorization: "findmyroom euyabfdthPJxI1T?F7tLGCdOdUchG!eiRaV?3/H2ozEx=tHwYw8Zi!pdqlEBNSS!FnKi2BUTh5Q!Van-qmMiC2NC17DgfSt6EWxIDSEhR=4aQJP3dy?5itwlp5defOTgxXmg=zZ/TQKPM1muJhwZ-gbzhpBw95H6rsUTPQTvt/cccoGJQsQuae5zLZ-EubC/UXgAwzYycDd3y4c?Zs-ryaxJOg!3H0TEfdw93?Ut/UxxawupzXdueyiBxswRwkGz",
      client: "findmyroom",
      "Content-Type": "application/json",
    },
  };

  const body = {
    "cityName": requirementsDetails.city,
    "micromarketName": requirementsDetails.locality,
    "firstName": requirementsDetails.name,
    "lastName": requirementsDetails.name,
    "phone": requirementsDetails.phoneNumber,
    accommodationType,
    "email": "test@test.com"
  }

  hqOptions.body = JSON.stringify(body);

  fetch(
    "https://api.thehelloworld.com/findmyroom/lead/create",
    hqOptions
  );
  return fetch(`https://${hostname}/api/v1/requirements/`, requestOptions).then(
    handleResponse
  );
}

function payment(plan, jT, whatsappGroupId) {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "omit",
    headers: {
      Authorization: "Bearer " + jT,
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify({
      plan,
      whatsappGroupId,
    }),
  };

  return fetch(
    `http://localhost:3500/api/v1/properties/payments/request`,
    requestOptions
  ).then(handleResponse);
}

function handleResponse(response) {
  return response.json().then((json) => {
    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        return Promise.reject("403");
      }

      const error = (json && json.message) || response.statusText;
      return Promise.reject(error);
    }
    return json;
  });
}
